import { ProfileData } from '@innedit/innedit';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import CMSView from '../../../../components/View';
import Form from '../../../../containers/Admin/Form';
import params from '../../../../params/profile/index.json';
import TemplateEspace from '../../../../templates/espace';
import requireEspace, { EspaceProps } from '../../../../utils/requireEspace';
import { UserProps } from '../../../../utils/requireUser';

const PageEspaceProfilesCreate: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  user,
}) => (
  <TemplateEspace espace={espace} user={user}>
    <CMSView>
      <Form
        itemPathnamePrefix={`/espaces/${espace.id}/profils/`}
        model={new ProfileData({ espace, params, espaceId: espace.id })}
        name="profile"
        type="create"
      />
    </CMSView>
  </TemplateEspace>
);

export default requireEspace(PageEspaceProfilesCreate);
